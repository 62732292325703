import { useCreateMutation, useFindQuery, useGetQuery, usePatchMutation } from '../../../../query'
import { useCallback, useState } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import config from '../../../../config'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/reducers'
import { message } from 'antd'
import getPlanId from '../../../../util/getPlanId'
import { Store } from 'rc-field-form/lib/interface'

const stripePromise = loadStripe(config.stripePublicKey)

const useSubscription = () => {
  const [renewing, setRenewing] = useState(false)
  const [cancelModalVisible, setCancelModalVisible] = useState(false)

  const clientId = useSelector((state: RootState) => state.appState.clientId)

  const {
    data: client
  } = useGetQuery('clients', clientId)

  const {
    data: stripeSubscriptions,
    status: stripeSubscriptionStatus
  } = useFindQuery('stripeSubscriptions', {})

  const [updateSubscription ,{
    status: updateSubscriptionStatus
  }] = usePatchMutation('stripeSubscriptions')

  const [createChurn ,{
    status: createChurnStatus
  }] = useCreateMutation('churns')

  const cancelSubscription = useCallback(async (values: Store) => {
    if (!stripeSubscriptions) {
      throw new Error('stripeSubscriptions not specified')
    }

    if (!client) {
      throw new Error('client not specified')
    }

    try {
      await updateSubscription({
        id: stripeSubscriptions.data[0].id,
        data: {
          cancel_at_period_end: true
        }
      })

      await createChurn({
        clientId: clientId as string,
        reason: values.reason,
        otherReason: values.otherReason,
        subscriptionPlanId: client.subscriptionPlanId
      })

      message.info('Subskrypcja została anulowana')
      setCancelModalVisible(false)
    } catch (e) {
      console.log('error', e)
    }
  }, [stripeSubscriptions])

  const [
    createStripeCheckoutSessions,
    {
      status: createStatus
    }
  ] = useCreateMutation('stripeCheckoutSessions')

  const [updateClient] = usePatchMutation('clients')

  const renew = useCallback(async () => {
    if (!client) {
      throw new Error('client not specified')
    }

    if (!stripeSubscriptions || !stripeSubscriptions.data?.length) {
      const stripe = await stripePromise
      if (!stripe) throw new Error('Stripe failed to initialize')

      try {
        setRenewing(true)

        // this is a simple workaround to get the planId after changing the subscription prices
        // the idea is that when a user wants to renew a subscription and they have an old planId,
        // we use a new planId
        const planId = getPlanId(client.subscriptionPlanId)

        await updateClient({
          id: (clientId as string),
          data: {
            subscriptionPlanId: planId
          }
        })

        const session = await createStripeCheckoutSessions({
          clientId: client._id,
          planId
        })

        const { error } = await stripe.redirectToCheckout({
          sessionId: session.id
        })
      } catch (e) {
        console.log(e)
      } finally {
        setRenewing(false)
      }
    } else {
      try {
        setRenewing(true)
        await updateSubscription({
          id: stripeSubscriptions.data[0].id,
          data: {
            cancel_at_period_end: false
          }
        })

        message.info('Subskrypcja została odnowiona')
      } catch (e) {
        console.log('error', e)
      } finally {
        setRenewing(false)
      }
    }
  }, [client, stripeSubscriptions])

  const [paymentMethod, setPaymentMethods] = useState(false)

  const changePaymentMethod = useCallback(async () => {
    if (!client) {
      throw new Error('client not specified')
    }

    const stripe = await stripePromise
    if (!stripe) throw new Error('Stripe failed to initialize')

    try {
      setPaymentMethods(true)

      const session = await createStripeCheckoutSessions({
        clientId: client._id,
        planId: client.subscriptionPlanId,
        mode: 'setup'
      })

      const { error } = await stripe.redirectToCheckout({
        sessionId: session.id
      })
    } catch (e) {
      console.log(e)
    } finally {
      setPaymentMethods(false)
    }
  }, [client])

  const isBeforeExpiryDate = client?.accessExpiryDate ? new Date(client.accessExpiryDate) > new Date() : false

  return {
    isActiveSubscription: !!stripeSubscriptions?.data[0] || isBeforeExpiryDate,
    stripeSubscription: stripeSubscriptions?.data[0],
    stripeSubscriptionStatus,
    cancelSubscription,
    deletingSubscription: updateSubscriptionStatus === 'loading' || createChurnStatus === 'loading',
    renew,
    renewing,
    client,
    paymentMethod,
    changePaymentMethod,
    cancelModalVisible,
    setCancelModalVisible
  }
}

export default useSubscription
